<template>
  <div class="page-404">
    <div class="content">
      <button
        class="button primary"
        @click="
          $router
            .push({
              path: '/auth',
            })
            .catch((err) => {})
        "
      >
        Login
      </button>
      <p class="_404">404</p>
      <p>The page you were looking for doesn't exist</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.page-404 {
  z-index: 999999;
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .content {
    text-align: center;
    ._404 {
      font-size: 220px;
      margin-bottom: 0;
    }
    p {
      font-size: 34px;
    }
  }
}
</style>